<template>
  <div>Chargement ...</div>
</template>

<script>
export default {
  created() {
    console.log(
      "---------------------- REFRESHING TOKEN ----------------------"
    );
    this.$store.getters.authService
      .signinSilentCallback()
      .then((user) => {
        //   //     window.history.replaceState(
        //   //       {},
        //   //       window.document.title,
        //   //       window.location.origin
        //   //     );
        //   localStorage.setItem("userToken", user.access_token);
        //   localStorage.setItem("workspaceSelected", user.profile.workspace_id);
        //   const returnUrl = localStorage.getItem("returnUrl");
        //   if (returnUrl) {
        //     localStorage.removeItem("returnUrl");
        //     this.$router.push(returnUrl);
        //   } else {
        //     this.$router.push("/");
        //   }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
